import axios from 'axios';
import { WSAPI } from './WSApi';
import Proxy from '../common/Proxy';
import { ApiServer } from '../common/SysConst';
export default class SeoApi extends WSAPI {
  getLayout () {
    axios.defaults.baseURL = '';
    let layout = axios.get('/layout.json');
    axios.defaults.baseURL = ApiServer;
    return layout;
  }
  @Proxy('Store')
  public getStoreData (lang) {
    return this.instance.post(this.apiPath + '/Seo/GetStoreData', lang).then((result) => {
      return result.data;
    });
  }
  // eslint-disable-next-line no-useless-constructor
  private constructor () {
    super();
  }
  private static instance: SeoApi;
  //* * 单例 */
  public static getInstance (): SeoApi {
    if (SeoApi.instance) { } else {
      SeoApi.instance = new SeoApi();
    }
    return SeoApi.instance;
  }
}
