export default class Store {
    private _Id : string;
    public get Id () : string {
      return this._Id;
    }
    public set Id (v : string) {
      this._Id = v;
    }

    private _Name : string;
    public get Name () : string {
      return this._Name;
    }
    public set Name (v : string) {
      this._Name = v;
    }

    private _Phone : string;
    public get Phone () : string {
      return this._Phone;
    }
    public set Phone (v : string) {
      this._Phone = v;
    }

    private _Email : string;
    public get Email () : string {
      return this._Email;
    }
    public set Email (v : string) {
      this._Email = v;
    }

    private _Address : string;
    public get Address () : string {
      return this._Address;
    }
    public set Address (v : string) {
      this._Address = v;
    }

    private _Fax : string;
    public get Fax () : string {
      return this._Fax;
    }
    public set Fax (v : string) {
      this._Fax = v;
    }

    private _Domain : string;
    public get Domain () : string {
      return this._Domain;
    }
    public set Domain (v : string) {
      this._Domain = v;
    }
    constructor (_Id = '', _Name = '', _Phone = '', _Email = '', _Address = '', _Fax = '', _Domain = '') {
      this._Id = _Id;
      this._Name = _Name;
      this._Phone = _Phone;
      this._Email = _Email;
      this._Address = _Address;
      this._Fax = _Fax;
      this._Domain = _Domain;
    }
}
